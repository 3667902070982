import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ubuntu/flomesh.io-main/src/layouts/Fixed/DocMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "运行脚本"
    }}>{`运行脚本`}</h3>
    <p>{`在 `}<a parentName="p" {...{
        "href": "/operating/cli/"
      }}>{`CLI 的介绍`}</a>{`中提到，Pipy 可以从静态文件或者 HTTP 接口进行脚本的加载，前面我们使用的也一直是静态文件的方式，这里也可以点击右上角的 ▶︎ 按钮即可运行脚本。`}</p>
    <p>{`但是有了 Repo 之后，我们就可以使用 HTTP 接口的方式了。此处，复制浏览器中的地址 `}<inlineCode parentName="p">{`http://localhost:6060/repo/hello/`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ pipy http://localhost:6060/repo/hello/
2021-10-09 12:28:52 [info] [codebase] GET http://localhost:6060/repo/hello/ -> 8 bytes
2021-10-09 12:28:52 [info] [codebase] GET /repo/hello/main.js -> 7 bytes
2021-10-09 12:28:52 [info] [config]
2021-10-09 12:28:52 [info] [config] Module /main.js
2021-10-09 12:28:52 [info] [config] ===============
2021-10-09 12:28:52 [info] [config]
`}</code></pre>
    <p>{`在提交脚本的改动之前，`}<inlineCode parentName="p">{`main.js`}</inlineCode>{` 脚本并不会监听任何端口，说明改动不生效。在点击提交按钮之后，就会发现 Pipy 重新加载了脚本，并正常监听 `}<inlineCode parentName="p">{`8080`}</inlineCode>{` 端口。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`pipy http://localhost:6060/repo/hello/
2021-10-09 12:28:52 [info] [codebase] GET http://localhost:6060/repo/hello/ -> 8 bytes
2021-10-09 12:28:52 [info] [codebase] GET /repo/hello/main.js -> 7 bytes
2021-10-09 12:28:52 [info] [config]
2021-10-09 12:28:52 [info] [config] Module /main.js
2021-10-09 12:28:52 [info] [config] ===============
2021-10-09 12:28:52 [info] [config]
2021-10-09 12:29:37 [info] [codebase] GET http://localhost:6060/repo/hello/ -> 8 bytes
2021-10-09 12:29:37 [info] [codebase] GET /repo/hello/main.js -> 65 bytes
2021-10-09 12:29:37 [info] [config]
2021-10-09 12:29:37 [info] [config] Module /main.js
2021-10-09 12:29:37 [info] [config] ===============
2021-10-09 12:29:37 [info] [config]
2021-10-09 12:29:37 [info] [config]  [Listen on :::8080]
2021-10-09 12:29:37 [info] [config]  ----->|
2021-10-09 12:29:37 [info] [config]        |
2021-10-09 12:29:37 [info] [config]       serveHTTP
2021-10-09 12:29:37 [info] [config]        |
2021-10-09 12:29:37 [info] [config]  <-----|
2021-10-09 12:29:37 [info] [config]
2021-10-09 12:29:37 [info] [listener] Listening on port 8080 at ::
2021-10-09 12:29:37 [info] Script reloaded
`}</code></pre>
    <p>{`测试一下：`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl http://localhost:8080
Hi!
`}</code></pre>
    <p>{`对脚本进行下修改，并保存、提交，`}<strong parentName="p">{`无需重启 pipy 实例`}</strong>{`。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`pipy()
  .listen(8080)
  .serveHTTP(
    new Message('Hello world!')
  )
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ curl http://localhost:8080
Hello world!
`}</code></pre>
    <h3 {...{
      "id": "衍生代码库"
    }}>{`衍生代码库`}</h3>
    <ol>
      <li parentName="ol">{`将上面的脚本稍微复杂化，实现个简单的逻辑与配置分离。`}</li>
    </ol>
    <p><strong parentName="p">{`main.js`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`(config =>
  pipy()
  .listen(config.port)
  .serveHTTP(
    () => new Message(config.greating)
  )
)(JSON.decode(pipy.load('config.json')))
`}</code></pre>
    <p>{`添加新的文件：`}<strong parentName="p">{`config.json`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  "port": 8080,
  "greating": "Hello world!"
}
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`衍生`}</li>
    </ol>
    <p>{`点击工具栏上的 “Derivates” 选择 “Derive new codebase”，添加衍生代码库的名字，比如 `}<inlineCode parentName="p">{`hi`}</inlineCode>{`。`}</p>
    <p><img parentName="p" {...{
        "src": "/img/docs/repo/derive-codebase.png",
        "alt": null
      }}></img></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`新代码库`}</li>
    </ol>
    <p>{`衍生的代码库界面中可以看到：`}</p>
    <ul>
      <li parentName="ul">{`文件列表中文件名变灰，说明与基础库中的内容一致；否则说明与基础库的文件内容有差异。`}</li>
      <li parentName="ul">{`工具栏上的 “Base” 按钮被激活，点击可以返回基础库的界面。`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/img/docs/repo/derivative-codebase.png",
        "alt": null
      }}></img></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`变更`}</li>
    </ol>
    <p>{`修改衍生库中 `}<inlineCode parentName="p">{`config.json`}</inlineCode>{` 中 `}<inlineCode parentName="p">{`greating`}</inlineCode>{` 的内容，为避免本地端口冲突修改端口号。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  "port": "8081",
  "greating": "Hi there!"
}
`}</code></pre>
    <p>{`保存并提交改动之后，可以看到 `}<inlineCode parentName="p">{`config.json`}</inlineCode>{` 变成了正常的颜色。`}</p>
    <p><img parentName="p" {...{
        "src": "/img/docs/repo/update-config.png",
        "alt": null
      }}></img></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`测试`}</li>
    </ol>
    <p>{`我们使用衍生库再启动一个 Pipy，并测试一下`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ pipy http://localhost:6060/repo/hi/
$ curl http://localhost:8080
Hello world!
$ curl http://localhost:8081
Hi there!
`}</code></pre>
    <p>{`上面是通过管理用户界面来对 codebase 进行更新发布等操作，实际这只是用户代码开发调试的场景。而 Pipy Repo 的推荐使用方式是通过 `}<a parentName="p" {...{
        "href": "/operating/repo/3-api"
      }}>{`REST API`}</a>{` 来进行 codebase 的操作。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      